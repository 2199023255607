import config from '@/config';
import { CART_ITEM_STATUS, PRODUCT_STATUS } from '@/config/common';
import {
  convertToLowerDenomination,
  formatToTwoDecimalString,
  parseFormatPriceNumberValueFromAPI,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import { getCartDetailsByPlanner } from '@/services/cart.service';
import * as HttpService from '@/services/http.service';
import { getAuth } from '@/services/identity.service';
import {
  CART_ITEM_OPERATION_URL,
  CLONE_CART_ITEM,
  CLONE_NON_ORDERED_CART,
  CLONE_ORDERED_CART,
  DELETE_CART_ITEM_IMAGE,
  DELETE_ITEM_FROM_CART_BY_PLANNER,
  EVENT_CART_CHECKOUT_FOR_PLANNER,
  GET_IMAGEKIT_AUTH,
  GET_READ_ONLY_CART_FOR_PLANNER,
  RECONCILE_CART_ITEM_MEDIA_FROM_IMAGEKIT,
  UPDATE_CART_INFO,
  UPDATE_CART_ITEM_MEDIA_SORT_ORDER,
  UPDATE_CART_ITEM_SHOW_PRODUCT_MEDIA,
  UPDATE_CART_ITEM_SORT_ORDER
} from '@/services/url.service';

export const cartCheckoutAndBookOrder = ({
  params: { userCartId, cartId },
  body
}) =>
  HttpService.postWithAuth(
    EVENT_CART_CHECKOUT_FOR_PLANNER({ userCartId, cartId }),
    body
  );

export const getReadOnlyCartForPlanner = ({ cartId }) =>
  HttpService.getWithAuth(GET_READ_ONLY_CART_FOR_PLANNER({ cartId }));

export const updateCartInfo = ({ userCartId, cartId, data }) =>
  HttpService.patchWithAuth(UPDATE_CART_INFO({ userCartId, cartId }), data);

export const updateCartItem = ({ userCartId, cartId, cartItemId, data }) =>
  HttpService.postWithAuth(
    CART_ITEM_OPERATION_URL({ userCartId, cartId, cartItemId }),
    data
  );

export const updateCartItemShowProductMediaToggle = ({
  cartId,
  cartItemId,
  data,
  userCartId
}) =>
  HttpService.putWithAuth(
    UPDATE_CART_ITEM_SHOW_PRODUCT_MEDIA({
      cartId,
      cartItemId,
      userCartId
    }),
    data
  );

export const cloneCartItem = ({ userCartId, cartId, cartItemId, data }) =>
  HttpService.postWithAuth(
    CLONE_CART_ITEM({ userCartId, cartId, cartItemId }),
    data
  );

export const cloneNonOrderedCart = ({ userCartId, cartId, data }) =>
  HttpService.postWithAuth(
    CLONE_NON_ORDERED_CART({ userCartId, cartId }),
    data
  );

export const cloneOrderedCart = ({ userCartId, cartId, data }) =>
  HttpService.postWithAuth(CLONE_ORDERED_CART({ userCartId, cartId }), data);

export const invokeUpdateCartItemSortOrder = ({ cartId, data }) =>
  HttpService.postWithAuth(UPDATE_CART_ITEM_SORT_ORDER({ cartId }), data);

export const deleteCartItemImage = ({ cartId, cartItemId, cartItemMediaId }) =>
  HttpService.deleteWithAuth(
    DELETE_CART_ITEM_IMAGE({ cartId, cartItemId, cartItemMediaId })
  );

export const getImageKitAuth = () =>
  HttpService.getWithAuth(GET_IMAGEKIT_AUTH());

export const deleteItemFromCart = ({ userCartId, cartItemId, cartId }) =>
  HttpService.deleteWithAuth(
    DELETE_ITEM_FROM_CART_BY_PLANNER({ userCartId, cartId, cartItemId })
  );

export const getCartDetailById = async ({ cartId }) => {
  const { id: userCartId } = getAuth() || {};
  const { entity, status, message } = await getCartDetailsByPlanner({
    userCartId,
    cartId
  });
  return { entity, status, message };
};

const CartItemGroupTypeFilters = {
  CHECKOUT_READY: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        productStatus === PRODUCT_STATUS.PUBLISHED &&
        cartItemStatus === CART_ITEM_STATUS.ACCEPTED
    ),
  CHECKOUT_STAGING: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        productStatus === PRODUCT_STATUS.PUBLISHED &&
        [CART_ITEM_STATUS.REQUEST_PENDING, CART_ITEM_STATUS.REQUESTED].includes(
          cartItemStatus
        )
    ),
  UNAVAILABLE: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        cartItemStatus !== CART_ITEM_STATUS.ORDERED &&
        productStatus !== PRODUCT_STATUS.PUBLISHED
    ),
  ORDERED: (items) =>
    items.filter(
      ({ status: cartItemStatus }) =>
        cartItemStatus === CART_ITEM_STATUS.ORDERED
    )
};

export const filterItemsByCartItemGroupType = ({ type, items = [] }) =>
  CartItemGroupTypeFilters[type](items);

const getGenericReducerCartItemField = (field) => (acc, cartItem) =>
  acc + parseFloat(cartItem[field] || 0);

export const getDeliveryChargeAmount = (cartItems) =>
  cartItems.reduce(getGenericReducerCartItemField('deliveryCharge'), 0);

export const getAdditionalChargeAmount = (cartItems) =>
  cartItems.reduce(getGenericReducerCartItemField('additionalCharge'), 0);

export const getProductPartnerListObject = ({ partnerId, legalName }) => [
  {
    value: partnerId,
    label: legalName
  }
];

export const getProductPartnerList = (productPartners) =>
  productPartners.map(({ partner: { legalName, id } }) => ({
    value: id,
    label: legalName
  }));

export const trimPercentSign = (str) => str.replace(/.%$/, '');

const getMarginPercentageValueForDisplay = ({
  unitPartnerPrice,
  unitSellingPrice
}) => {
  let marginPercentageValue = null;

  if (unitSellingPrice > 0) {
    marginPercentageValue = formatToTwoDecimalString({
      value: convertToLowerDenomination(
        (100 * (unitSellingPrice - unitPartnerPrice)) / unitSellingPrice
      )
    });
  }

  return marginPercentageValue ? `${marginPercentageValue} %` : '';
};

export const convertValueFieldsToLowerDenomination = (itemDetail) => {
  const fieldsToUpdate = [
    'unitPartnerPrice',
    'unitSellingPrice',
    'unitListedPrice',
    'unitListedDiscount',
    'sellingPrice',
    'listedDiscount',
    'unitRackPrice'
  ];

  const updatedItemDetail = {};
  Object.keys(itemDetail).forEach((key) => {
    updatedItemDetail[key] = fieldsToUpdate.includes(key)
      ? convertToLowerDenomination(itemDetail[key])
      : itemDetail[key];

    if (key === 'haflaMarginPercent') {
      updatedItemDetail[key] = Number(trimPercentSign(itemDetail[key]));
    }
  });

  return updatedItemDetail;
};

export const onQuantityChange = ({ inputtedQuantity, getValues, setValue }) => {
  const currentUnitListedPrice = getValues('unitListedPrice');
  const currentUnitListedDiscount = getValues('unitListedDiscount');
  const currentUnitPartnerPrice = getValues('unitPartnerPrice');
  const computedListedPrice = inputtedQuantity * currentUnitListedPrice;
  const computedListedDiscount = inputtedQuantity * currentUnitListedDiscount;
  const computedTotalPartnerPrice = inputtedQuantity * currentUnitPartnerPrice;

  setValue('listedDiscount', computedListedDiscount);
  setValue('sellingPrice', computedListedPrice - computedListedDiscount);
  setValue('quantity', inputtedQuantity, {
    shouldValidate: true
  });
  setValue('partnerPrice', computedTotalPartnerPrice);
};

export const onProductPartnerSelectionChange = ({
  partnerId: selectedPartnerId,
  getValues,
  setValue,
  productPartners,
  setUpdatePartnerSelectionFlag,
  clearErrors,
  updatePartnerSelectionFlag
}) => {
  const currentQuantity = getValues('quantity');
  const currentUnitSellingPrice = getValues('unitSellingPrice');
  const { unitPartnerPrice: unitPartnerPriceValue } = productPartners.find(
    ({ partner: { id: partnerId } }) => selectedPartnerId === partnerId
  );

  setUpdatePartnerSelectionFlag(!updatePartnerSelectionFlag);
  setValue('partnerId', selectedPartnerId);
  setValue(
    'unitPartnerPrice',
    parseFormatPriceValueFromAPI(unitPartnerPriceValue)
  );
  setValue(
    'partnerPrice',
    parseFormatPriceValueFromAPI(unitPartnerPriceValue * currentQuantity)
  );
  setValue(
    'haflaMarginPercent',
    getMarginPercentageValueForDisplay({
      unitPartnerPrice: parseFormatPriceNumberValueFromAPI(
        unitPartnerPriceValue
      ),
      unitSellingPrice: currentUnitSellingPrice
    })
  );
  clearErrors();
};

export const onUnitPartnerPriceChange = ({
  inputtedUnitPartnerPrice,
  getValues,
  setValue
}) => {
  const currentQuantity = getValues('quantity');
  const currentUnitSellingPrice = getValues('unitSellingPrice');

  setValue(
    'haflaMarginPercent',
    getMarginPercentageValueForDisplay({
      unitPartnerPrice: inputtedUnitPartnerPrice,
      unitSellingPrice: currentUnitSellingPrice
    })
  );

  setValue('unitPartnerPrice', inputtedUnitPartnerPrice, {
    shouldValidate: true
  });
  setValue('partnerPrice', inputtedUnitPartnerPrice * currentQuantity);
};

export const onUnitListedPriceChange = ({
  inputtedUnitListedPrice,
  getValues,
  setValue
}) => {
  const currentQuantity = getValues('quantity');
  const currentUnitPartnerPrice = getValues('unitPartnerPrice');
  const computedListedPrice = inputtedUnitListedPrice * currentQuantity;

  setValue('unitListedDiscount', 0);
  setValue('listedDiscount', 0);
  setValue('sellingPrice', computedListedPrice);
  setValue('unitListedPrice', inputtedUnitListedPrice, {
    shouldValidate: true
  });
  setValue('unitSellingPrice', inputtedUnitListedPrice);
  setValue(
    'haflaMarginPercent',
    getMarginPercentageValueForDisplay({
      unitPartnerPrice: currentUnitPartnerPrice,
      unitSellingPrice: inputtedUnitListedPrice
    })
  );
};

const isAwaitingPriceFinalization = (itemStatus) =>
  ['REQUEST_PENDING', 'REQUESTED'].includes(itemStatus);

export const onChangeOfNotes = ({
  inputtedNotes,
  setValue,
  isPriceNote,
  itemStatus,
  dbName,
  setIsUnitListedPriceNotEditable
}) => {
  const checkForNotAwaitingPriceFinalization =
    !isAwaitingPriceFinalization(itemStatus);
  setValue(dbName, inputtedNotes, {
    shouldValidate: true
  });

  isPriceNote &&
    checkForNotAwaitingPriceFinalization &&
    setIsUnitListedPriceNotEditable(!inputtedNotes);
};

export const reconcileCartItemMediaWithImageKit = ({ cartId, cartItemId }) =>
  HttpService.postWithAuth(RECONCILE_CART_ITEM_MEDIA_FROM_IMAGEKIT(), {
    cartId,
    cartItemId
  });

export const getSelectedPartner = ({ productPartners, partnerId }) => {
  const {
    partner: { legalName: selectedPartnerDisplayName }
  } = productPartners.find(
    ({ partnerId: partnerIdToInspect }) => partnerIdToInspect === partnerId
  );

  return selectedPartnerDisplayName;
};

export const getPartnerTaxDetailsForNonOrderedCart = (cartItems) => {
  const {
    taxableAmount: partnerTaxableAmount,
    nonTaxableAmount: partnerNonTaxableAmount
  } = cartItems.reduce(
    ({ taxableAmount, nonTaxableAmount }, cartItem) => {
      const {
        partnerId: selectedPartnerId,
        product: { productPartners: productPartnerList },
        quantity,
        unitPartnerPrice
      } = cartItem;

      const {
        unitPartnerPrice: defaultUnitPartnerPrice,
        partner: { partnerStatutory }
      } = productPartnerList.find(
        ({ partnerId }) => partnerId === selectedPartnerId
      );

      const applicableUnitPartnerPrice =
        unitPartnerPrice || defaultUnitPartnerPrice;
      const partnerPrice = applicableUnitPartnerPrice * quantity;

      let updatedTaxableAmount = taxableAmount;
      let updatedNonTaxableAmount = nonTaxableAmount;
      if (partnerStatutory?.vatApplicable) {
        updatedTaxableAmount += partnerPrice;
      } else {
        updatedNonTaxableAmount += partnerPrice;
      }

      return {
        taxableAmount: updatedTaxableAmount,
        nonTaxableAmount: updatedNonTaxableAmount
      };
    },
    { taxableAmount: 0, nonTaxableAmount: 0 }
  );

  const partnerVATAmount = (partnerTaxableAmount * config.vatPercentage) / 100;

  return {
    partnerPreVATAmount: partnerTaxableAmount + partnerNonTaxableAmount,
    partnerVATAmount
  };
};

export const getPartnerTaxDetailsForOrderedCart = (cartItems) => {
  const {
    taxableAmount: partnerTaxableAmount,
    nonTaxableAmount: partnerNonTaxableAmount
  } = cartItems.reduce(
    ({ taxableAmount, nonTaxableAmount }, cartItem) => {
      const {
        cartItemDerivedValue: {
          unitPartnerPrice,
          unitPartnerPrice: defaultUnitPartnerPrice,
          partnerVatApplicable
        },
        quantity
      } = cartItem;

      const applicableUnitPartnerPrice =
        unitPartnerPrice || defaultUnitPartnerPrice;
      const partnerPrice = applicableUnitPartnerPrice * quantity;

      let updatedTaxableAmount = taxableAmount;
      let updatedNonTaxableAmount = nonTaxableAmount;
      if (partnerVatApplicable) {
        updatedTaxableAmount += partnerPrice;
      } else {
        updatedNonTaxableAmount += partnerPrice;
      }

      return {
        taxableAmount: updatedTaxableAmount,
        nonTaxableAmount: updatedNonTaxableAmount
      };
    },
    { taxableAmount: 0, nonTaxableAmount: 0 }
  );

  const partnerVATAmount = (partnerTaxableAmount * config.vatPercentage) / 100;

  return {
    partnerPreVATAmount: partnerTaxableAmount + partnerNonTaxableAmount,
    partnerVATAmount
  };
};

export const updateCartItemMediaSortOrderAPI = ({
  cartId,
  cartItemId,
  cartItemMediaToSort
}) =>
  HttpService.postWithAuth(
    UPDATE_CART_ITEM_MEDIA_SORT_ORDER({ cartId, cartItemId }),
    { cartItemMediaToSort }
  );
