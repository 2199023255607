// TODO: Pass environment variables through config before depending upon them.
import {
  HOST_API_BASE_URL,
  OPS_API_BASE_URL
} from '@/services/connections.service';

const interpolatePath = ({ pathname, pathParams }) =>
  Object.entries(pathParams).reduce(
    (template, [key, value]) => template.replace(`:${key}`, value ?? ''),
    pathname
  );

const buildQueryString = ({ queryParams }) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return queryString ? `?${queryString}` : '';
};

export const getURLPath = ({ pathname, pathParams = {}, queryParams = {} }) => {
  const interpolatedPath = interpolatePath({ pathname, pathParams });
  const queryString = buildQueryString({ queryParams });
  return `${interpolatedPath}${queryString}`;
};

const getAPIURL = ({
  baseUrl,
  pathname,
  pathParams = {},
  queryParams = {}
}) => {
  const path = getURLPath({ pathname, pathParams, queryParams });
  const url = new URL(`${baseUrl}${path}`);
  return url.toString();
};

const getHostAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: HOST_API_BASE_URL, pathname, pathParams });

const getOpsAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: OPS_API_BASE_URL, pathname, pathParams });

export const DELETE_CLAIMS_URL = getHostAPIURL('/claims/delete');

export const GET_CUSTOMER_TESTIMONIALS = getHostAPIURL('/testimonials');

export const GET_GOOGLE_CUSTOMER_TESTIMONIALS = getHostAPIURL('/reviews');

export const GET_EVENT_GALLERY = (eventCategoryId) =>
  getHostAPIURL('/event-gallery?eventCategoryId=:eventCategoryId', {
    eventCategoryId
  });

export const CONTACT_US = getHostAPIURL('/contact-us');

export const CONTACT_US_USER = getHostAPIURL('/user-contact-us');

export const GET_WORKING_HOURS = getHostAPIURL('/working-hours');

export const GET_COMMUNICATION_MODE = getHostAPIURL('/communication-modes');

export const LEAD_CAPTURE = getHostAPIURL('/leads');

export const GET_VENUE_TYPES = getHostAPIURL('/venues');

export const GET_USER_EVENT_CARTS = (userCartId, userEventId) =>
  getHostAPIURL('/users/:userCartId/carts/list?userEventId=:userEventId', {
    userCartId,
    userEventId
  });

export const GET_CART_DETAILS = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/details', {
    userCartId,
    cartId
  });

export const GET_CART_DETAILS_BY_PLANNER = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/details', {
    cartId,
    userCartId
  });

export const UPDATE_CART_ITEM_SHOW_PRODUCT_MEDIA = ({
  cartId,
  cartItemId,
  userCartId
}) =>
  getOpsAPIURL(
    '/users/:userCartId/carts/:cartId/cart-item/:cartItemId/showProductMedia',
    {
      cartId,
      cartItemId,
      userCartId
    }
  );

export const UPDATE_CART_ITEM_SORT_ORDER = ({ cartId }) =>
  getOpsAPIURL('/cart/:cartId/cart-item/update-sort-order', { cartId });

export const CART_ITEM_OPERATION_URL = ({ userCartId, cartId, cartItemId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    userCartId,
    cartId,
    cartItemId
  });

export const CLONE_CART_ITEM = ({ userCartId, cartId, cartItemId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId/clone', {
    userCartId,
    cartId,
    cartItemId
  });

export const CLONE_NON_ORDERED_CART = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/clone', {
    userCartId,
    cartId
  });

export const CLONE_ORDERED_CART = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/clone-ordered-cart', {
    userCartId,
    cartId
  });

export const UPDATE_CART_INFO = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId', { userCartId, cartId });

export const DELETE_ITEM_FROM_CART_BY_PLANNER = ({
  cartId,
  cartItemId,
  userCartId
}) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    cartId,
    cartItemId,
    userCartId
  });

export const ADD_ITEM_TO_DEFAULT_CART = (userCartId) =>
  getHostAPIURL('/users/:userCartId/default-cart/cart-item', { userCartId });

export const ADD_ITEM_TO_CART = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/cart-item', {
    cartId,
    userCartId
  });

export const ADD_ITEM_TO_CART_BY_PLANNER = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/cart-item', {
    cartId,
    userCartId
  });

export const AUTHENTICATE_CART = getHostAPIURL('/authenticate-cart');

export const GET_PRODUCTS = (
  skip,
  limit,
  parentCategory,
  category,
  subCategory,
  sortOrder
) =>
  getHostAPIURL(
    '/products?skip=:skip&limit=:limit&parentCategory=:parentCategory&category=:category&subCategory=:subCategory&sortOrder=:sortOrder',
    {
      skip,
      limit,
      parentCategory,
      category,
      subCategory,
      sortOrder
    }
  );

export const GET_PRODUCT_KIDS_BDAY = getHostAPIURL('/products/ready-packages');

export const GET_PRODUCT = (slug) => getHostAPIURL('/products/:slug', { slug });

export const GET_PRODUCT_BY_ID = (productId) =>
  getHostAPIURL('/products/:productId/id', { productId });

export const GET_PRODUCT_BREADCRUMBS = (slug) =>
  getHostAPIURL('/products/:slug/breadcrumbs', { slug });

export const CREATE_GUEST_USER = getHostAPIURL('/guest-user');

export const CREATE_USER = getHostAPIURL('/users');

export const USER_LOGIN = getHostAPIURL('/sessions');

export const RECENT_OTP = getHostAPIURL('/sessions/resend-otp');

export const GET_USER = (userId) => getHostAPIURL('/users/:userId', { userId });

export const GET_CATEGORY_TREE = getHostAPIURL('/category/tree');

export const GET_CATEGORY_SEO_DETAILS = (categorySlug) =>
  getHostAPIURL('/categories/:categorySlug/seo-details', { categorySlug });

export const GET_USER_ONGOING_ORDERS = (userId, skip, limit) =>
  getHostAPIURL('/users/:userId/orders?type=ONGOING&skip=:skip&limit=:limit', {
    userId,
    skip,
    limit
  });

export const GET_USER_COMPLETED_ORDERS = (userId, skip, limit) =>
  getHostAPIURL(
    '/users/:userId/orders?type=COMPLETED&skip=:skip&limit=:limit',
    {
      userId,
      skip,
      limit
    }
  );

export const GET_USER_ORDER_DETAILS = (userId, orderId) =>
  getHostAPIURL('/users/:userId/orders/:orderId', { userId, orderId });

export const UPDATE_CART_EXTRA_INFO = getHostAPIURL(
  '/update-extra-property-in-cart'
);

export const POST_CHECKOUT_EVENT_PRIORITY_ENQUIRY = getHostAPIURL(
  '/inquiries/priority-event-inquiry'
);

export const GET_CHECKOUT_EVENT_LOOKUP = getHostAPIURL('/checkout/eventType');

export const EVENT_CART_CHECKOUT = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/checkout-non-default-cart', {
    userCartId,
    cartId
  });

export const EVENT_CART_CHECKOUT_FOR_PLANNER = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/checkout-non-default-cart', {
    userCartId,
    cartId
  });

export const GET_CITY_DETAILS = getHostAPIURL('/cities');

export const GET_SEARCH_SUGGESTIONS = (text) =>
  getHostAPIURL('/search-suggestions?text=:text', { text });

export const APPLY_PROMO_CODE = getHostAPIURL('/apply-promo-code');

export const REMOVE_PROMO_CODE = getHostAPIURL('/remove-promo-code');

export const UPDATE_USER_DETAILS = (userId) =>
  getHostAPIURL('/users/:userId/user-details', { userId });

export const UPDATE_USER_ADDRESS = (userId) =>
  getHostAPIURL('/users/:userId/address', { userId });

export const POST_RFQ_QUOTE_DETAILS = getHostAPIURL('/rfq-submission-details');

export const UPDATE_USER_TIMEZONE = (userId) =>
  getHostAPIURL('/users/:userId/user-time-zone', { userId });

export const GET_PUBLISHED_COLLECTIONS = (skip, limit) =>
  getHostAPIURL('/published-collections?skip=:skip&limit=:limit', {
    skip,
    limit
  });

export const GET_COLLECTION_BY_SLUG = (slug) =>
  getHostAPIURL('/collections/:slug', { slug });

export const BOOK_TICKET_DETAILS = getHostAPIURL('/inquiry/booking');

export const POST_VENDOR_REGISTRATION_ENQUIRY = getHostAPIURL(
  '/inquiries/vendor-registration'
);

export const GET_HAFLA_BLOGS = getHostAPIURL('/blogs');

export const CREATE_USER_EVENT = (userId) =>
  getHostAPIURL('/users/:userId/user-events', {
    userId
  });

export const UPDATE_USER_EVENT = (userId, userEventId) =>
  getHostAPIURL(
    '/users/:userId/user-events/:userEventId/update-user-event-profile',
    {
      userId,
      userEventId
    }
  );

export const GET_PRICE_RANGE_BY_SLUG = getHostAPIURL('/product-pricerange/');

export const GET_EVENT_SITE_TYPE_LIST = getHostAPIURL('/event-site-types');

export const GET_CART_QUOTATION = ({ userCartId, cartId }) =>
  getHostAPIURL('/user/:userCartId/carts/:cartId/quote-pdf', {
    userCartId,
    cartId
  });

export const GET_CARTS_BY_EVENT_ID = ({ userCartId, userEventId }) =>
  getHostAPIURL('/users/:userCartId/user-events/:userEventId/carts', {
    userCartId,
    userEventId
  });

export const GET_ORDER_MEDIA_BY_EVENT_ID = ({ userId, userEventId }) =>
  getHostAPIURL('/users/:userId/user-events/:userEventId/orderMedia', {
    userId,
    userEventId
  });

export const ADD_NEW_CART = (userId) =>
  getOpsAPIURL('/users/:userId/cart', { userId });

export const GET_USER_EVENT_DETAILS_BY_ID = ({
  userId,
  userEventId,
  isUserPlanner
}) => {
  const getURL = isUserPlanner ? getOpsAPIURL : getHostAPIURL;
  return getURL('/users/:userId/user-events/:userEventId/details', {
    userId,
    userEventId
  });
};

export const GET_USER_ONGOING_EVENTS = (userId, skip, limit) =>
  getHostAPIURL(
    '/users/:userId/user-events?type=ONGOING&skip=:skip&limit=:limit',
    {
      userId,
      skip,
      limit
    }
  );

export const GET_USER_PAST_EVENTS = (userId, skip, limit) =>
  getHostAPIURL(
    '/users/:userId/user-events?type=PAST&skip=:skip&limit=:limit',
    {
      userId,
      skip,
      limit
    }
  );

export const UPDATE_ARCHIVED_CART_STATUS = (userCartId, cartId) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/isArchived', {
    cartId,
    userCartId
  });

export const DEFAULT_CART_CHECKOUT = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/checkout-default-cart', {
    cartId,
    userCartId
  });

export const CREATE_TENDER_AND_DISPATCH_BIDS = () => getOpsAPIURL('/tenders');

export const REVISE_TENDER_AND_DISPATCH_BIDS = ({ referenceId }) =>
  getOpsAPIURL('/tenders/revise/:referenceId', { referenceId });

export const RECONCILE_CART_ITEM_MEDIA_FROM_IMAGEKIT = () =>
  getOpsAPIURL('/cart-item-media/reconcile');

export const DELETE_CART_ITEM_IMAGE = ({
  cartId,
  cartItemId,
  cartItemMediaId
}) =>
  getOpsAPIURL(
    '/cart/:cartId/cart-item/:cartItemId/cart-item-media/:cartItemMediaId',
    { cartId, cartItemId, cartItemMediaId }
  );

export const GET_IMAGEKIT_AUTH = () => getOpsAPIURL('/imagekit/auth');

export const GET_CART_CHECKOUT_INFO = ({ cartId, userCartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/checkout', {
    cartId,
    userCartId
  });

export const UPDATE_CART_ITEM_MEDIA_SORT_ORDER = ({ cartId, cartItemId }) =>
  getOpsAPIURL(
    '/cart/:cartId/cart-item/:cartItemId/cart-item-media/update-sort-order',
    { cartId, cartItemId }
  );

export const CHECKOUT_CART_AND_PAY = ({ cartId, userCartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/checkout', {
    cartId,
    userCartId
  });

export const GET_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_INFO = ({
  orderId,
  userId
}) =>
  getHostAPIURL('/users/:userId/orders/:orderId/anybody-pay-due', {
    orderId,
    userId
  });

export const POST_ANYBODY_CAN_PAY_ORDER_DUE_PAYMENT_URL_REQUEST = ({
  orderId,
  userId
}) =>
  getHostAPIURL('/users/:userId/orders/:orderId/anybody-pay-due', {
    orderId,
    userId
  });

export const POST_ORDER_DUE_PAYMENT_URL_REQUEST = ({ orderId, userId }) =>
  getHostAPIURL('/users/:userId/orders/:orderId/pay-due', {
    orderId,
    userId
  });

export const GET_TENDER_RESPONSE = ({ referenceId }) =>
  getOpsAPIURL('/tenders/responses/:referenceId', { referenceId });

export const GET_PAYMENT_STATUS = ({ orderId, paymentId, userCartId }) =>
  getHostAPIURL('/users/:userCartId/orders/:orderId/payments/:paymentId', {
    orderId,
    paymentId,
    userCartId
  });

export const GET_READ_ONLY_CART_FOR_PUBLIC = ({ cartId }) =>
  getHostAPIURL('/carts/:cartId/read-only-details', {
    cartId
  });

export const GET_READ_ONLY_CART_FOR_PLANNER = ({ cartId }) =>
  getOpsAPIURL('/carts/:cartId/read-only-details', {
    cartId
  });

export const POST_METABASE_DASHBOARD_SIGNED_URL = () =>
  getOpsAPIURL('/mb/generate-signed-url');

export const GET_ORDER_INVOICE = ({ orderId }) =>
  getOpsAPIURL('/orders/:orderId/invoice', { orderId });

export const GET_LEAD_EXIT_REASONS = () => getOpsAPIURL('/lead-exit-reasons');

export const UPDATE_LEAD_EXIT_REASON = ({ userEventId, userId }) =>
  getHostAPIURL('/users/:userId/user-event/:userEventId/lead-exit-reason', {
    userId,
    userEventId
  });
